'use client';

import { ReactNode, useMemo, useState } from 'react';

import { StoreChain } from '@api';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface StoreChainProviderProps {
  initialChain?: StoreChain | null;
  children: ReactNode;
}

interface ContextValue {
  chain?: StoreChain | null;
  setChain: (chain: StoreChain | null) => void;
}

const Context = createSafeContext<ContextValue>();

export const useStoreChain = () => useSafeContext(Context);

const StoreChainProvider = ({ children, initialChain }: StoreChainProviderProps) => {
  const [chain, setChain] = useState(initialChain);
  // useEffect(() => {
  //   if (chain) {
  //     Cookies.set('storeChain', JSON.stringify(chain), {
  //       expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // + 1 year
  //     });
  //   } else {
  //     Cookies.remove('storeChain');
  //   }
  // }, [chain]);
  const value = useMemo(() => {
    return {
      chain,
      setChain,
    };
  }, [chain]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default StoreChainProvider;
