'use client';

import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface NotificationBannerProviderProps {
  children?: ReactNode;
}

interface ContextValue {
  activeBanner?: NotificationBannerConfig;
  removeBanner: (id: NotificationBannerConfig['id']) => void;
  setBanner: (config: NotificationBannerConfig) => void;
  increaseContainerCount: () => void;
  decreaseContainerCount: () => void;
  containerCount: number;
}

interface NotificationBannerConfig {
  id: string;
  priority: number;
}

const Context = createSafeContext<ContextValue>();

export const useNotificationBanner = () => useSafeContext(Context);

const NotificationBannerProvider = ({ children }: NotificationBannerProviderProps) => {
  const [containerCount, setContainerCount] = useState(0);
  const [banners, setBanners] = useState<Record<string, NotificationBannerConfig>>({});
  const activeBanner = useMemo(() => {
    const bannerList = Object.values(banners);

    if (bannerList.length <= 1) {
      return bannerList[0];
    }

    return bannerList.reduce((prev, curr) => {
      return prev.priority > curr.priority ? prev : curr;
    });
  }, [banners]);
  const setBanner = useCallback(({ id, priority }: NotificationBannerConfig) => {
    setBanners((oldBanners) => {
      if (id in oldBanners && oldBanners[id].priority === priority) {
        return oldBanners;
      }

      return {
        ...oldBanners,
        [id]: {
          id,
          priority,
        },
      };
    });
  }, []);
  const removeBanner = useCallback((id: NotificationBannerConfig['id']) => {
    setBanners((oldBanners) => {
      if (id in oldBanners) {
        const newBanners = {
          ...oldBanners,
        };

        delete newBanners[id];

        return newBanners;
      }

      return oldBanners;
    });
  }, []);
  const increaseContainerCount = useCallback(
    () => setContainerCount((oldValue) => oldValue + 1),
    [],
  );
  const decreaseContainerCount = useCallback(
    () => setContainerCount((oldValue) => oldValue - 1),
    [],
  );
  const value = useMemo(() => {
    return {
      activeBanner,
      setBanner,
      removeBanner,
      increaseContainerCount,
      decreaseContainerCount,
      containerCount,
    };
  }, [
    activeBanner,
    setBanner,
    removeBanner,
    decreaseContainerCount,
    increaseContainerCount,
    containerCount,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default NotificationBannerProvider;
