'use client';

import React, { ReactNode, useMemo, useState } from 'react';

import { useMediaQuery } from 'usehooks-ts';

import ProfileMenu from '@store:web/components/layout/ProfileMenu/ProfileMenu';
import { createSafeContext, useSafeContext } from '@utilities/context';

interface ContextValue {
  isOpen: boolean;
  close: () => void;
  open: () => void;
  isAvailable: boolean;
}

const Context = createSafeContext<ContextValue>();

export const useProfileMenu = () => useSafeContext(Context);

const ProfileMenuProvider = ({ children }: { children?: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isTablet = useMediaQuery('(min-width: 992px)');
  const isAvailable = !isTablet;
  const actions = useMemo(() => {
    return {
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
    };
  }, []);
  const value = useMemo(() => {
    return {
      isOpen,
      isAvailable,
      ...actions,
    };
  }, [isOpen, actions, isAvailable]);

  return (
    <Context.Provider value={value}>
      {isAvailable && <ProfileMenu isOpen={isOpen} onClose={actions.close} />}
      {children}
    </Context.Provider>
  );
};

export default ProfileMenuProvider;
