'use client';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { SNAPSHOT } from '@shared/config/snapshot';

import type Client from '@snapshot-labs/snapshot.js/dist/sign';
import { createSafeContext, useSafeContext } from '@utilities/context';

interface ContextValue {
  client?: Client;
  vote: Client['vote'];
}

const Context = createSafeContext<ContextValue>();

export const useSnapshot = () => useSafeContext(Context);

interface SnapshotProviderProps {
  children?: ReactNode;
}

const SnapshotProvider = ({ children }: SnapshotProviderProps) => {
  const [client, setClient] = useState<Client>();
  const vote: Client['vote'] = useCallback(
    async (...args) => {
      let initializedClient = client;

      if (!initializedClient) {
        const snapshot = await import('@snapshot-labs/snapshot.js').then(
          (module) => module.default,
        );

        initializedClient = new snapshot.Client712(SNAPSHOT.hub);

        setClient(initializedClient);
      }

      return initializedClient.vote(...args);
    },
    [client],
  );
  const value = useMemo(() => {
    return {
      client,
      vote,
    };
  }, [client, vote]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default SnapshotProvider;
