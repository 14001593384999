'use client';

import dynamic from 'next/dynamic';

import React, { ReactNode } from 'react';

import { type AuthModalProperties } from '@shared/common/components/AuthModal';

import { roadToTgeQuests } from '@store:web/components/pages/StoryPage/hardcodedStories/roadToTgeQuests';
import { stormTrade } from '@store:web/components/pages/StoryPage/hardcodedStories/stormTrade';
import { zealyLeaderboard } from '@store:web/components/pages/StoryPage/hardcodedStories/zealyLeaderboard';

const AuthModal = dynamic<AuthModalProperties>(
  () => import('@shared/common/components/AuthModal').then((el) => el.AuthModal),
  {
    ssr: false,
  },
);
const ConnectAndSignModal = dynamic(
  () => import('@shared/common/components/modals/ConnectAndSignModal'),
  {
    ssr: false,
  },
);
const CompleteMagicIDModal = dynamic(
  () => import('@store:web/components/common/modals/CompleteMagicIDModal/CompleteMagicIDModal'),
  {
    ssr: false,
  },
);
const EmailLoginError = dynamic(
  () => import('@store:web/components/common/modals/EmailLoginError'),
  {
    ssr: false,
  },
);

const RateLimitedModal = dynamic(
  () => import('@store:web/components/common/modals/RateLimitedModal'),
  {
    ssr: false,
  },
);

const SupportModal = dynamic(() => import('@shared/common/components/modals/SupportModal'));

const BanxaModal = dynamic(() => import('@shared/common/components/modals/BanxaModal'));

const CustomContentModal = dynamic(
  () => import('@shared/common/components/modals/CustomContentModal'),
);

const GlobalModalsStore = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <ConnectAndSignModal />
      <AuthModal
        pathExcludedFromRedirect={[
          `/stories/${zealyLeaderboard.id}`,
          `/stories/${roadToTgeQuests.id}`,
          `/stories/${stormTrade.id}`,
          '/stories/youhodler-100000-yhdl-campaign',
        ]}
      />
      <CompleteMagicIDModal />
      <EmailLoginError />
      <RateLimitedModal />
      <BanxaModal />
      <CustomContentModal />
      <SupportModal />
    </>
  );
};

export default GlobalModalsStore;
